import React, { useContext, useEffect } from "react";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import Cartfilters from "../Components/Cartfilters/Cartfilters";
import ListProductsCart from "../Components/Lists/ListProductsCart";
import { SearchContext } from "../Context/SearchContext";

export default function CartPage() {
  const { setSearch } = useContext(SearchContext);

  useEffect(() => {
    setSearch("");
  }, [setSearch]);

  return (
    <>
      <Header title="Carrito 1/2" components={<Cartfilters />} />
      <ListProductsCart />
      <Footer active="cart"></Footer>
    </>
  );
}
