import React, { useState, useContext } from "react";
import { CartContext } from "../../Context/cartContext";
import "./checkout.css";

export default function Checkout() {
  const { cart } = useContext(CartContext);

  const arrayTotales = [];
  cart.map( item => {
    return arrayTotales.push( (item.PrecioFinal * item.cantidad) )
  });
  let total = arrayTotales.reduce((acumulador, numero) => acumulador + numero, 0);
  total = total.toLocaleString(
    "es-AR",
    {
      style: "currency",
      currency: "ARS",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }
  );

  const [formData, setFormData] = useState({
    nombre: "",
    razon: "",
    cuit: "",
    telefono: "",
    consulta: "",
    carrito: cart,
    total,
  });

  const handleChange = (e) => {
    // console.log(cart);
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let textCart = [];

    formData.carrito.map((item) => {
      return textCart.push([`Código: ${item.Codigo}\nProducto: ${item.NombreWeb}\nCantidad: ${item.cantidad}\nPrecio Unidad: $${item.PrecioFinal}\nSubtotal: $${item.PrecioFinal * item.cantidad}\n---`]);
    });

    let textEnd = textCart.join("\n");

    let contacto = `Datos de contacto:\nNombre: ${formData.nombre}\nRazón social: ${formData.razon}\nCUIT/CUIL: ${formData.cuit}\nTeléfono: ${formData.telefono}\n\nConsulta adicional: ${formData.consulta}`;

    let mensajeWhatsapp = `¡Hola! \nMi nombre es ${formData.nombre}, quiero solicitar el siguiente presupuesto \n\n${textEnd}\n\n${contacto}\n\n\nTotal: ${total}`;

    const mensajeWhatsappEnd = encodeURIComponent(mensajeWhatsapp);

    window.open(
      `https://api.whatsapp.com/send?phone=+5493513576925&text=${mensajeWhatsappEnd}`
    );
  };

  return (
    <div className="container-checkout">
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="nombre"
          placeholder="Nombre completo"
          value={formData.nombre}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="razon"
          placeholder="Razón social"
          value={formData.razon}
          onChange={handleChange}
        />
        <input
          type="text"
          name="cuit"
          placeholder="CUIT / CUIL"
          value={formData.cuit}
          onChange={handleChange}
        />
        <input
          type="text"
          name="telefono"
          placeholder="Teléfono"
          value={formData.telefono}
          onChange={handleChange}
        />
        <textarea
          name="consulta"
          id=""
          cols="30"
          rows="10"
          placeholder="Consultas adicionales"
          value={formData.consulta}
          onChange={handleChange}
        ></textarea>
        <div className="container-buttons">
          <button type="submit" className="button button-blue">
            Envíanos tu pedido
          </button>
        </div>
      </form>
    </div>
  );
}
