import React, { createContext, useState } from "react";
export const CartContext = createContext();

export default function CartProvider({ children }) {
  const [cart, setCart] = useState([]);

  const addItemsCart = (item, cantidad, replace) => {
    if (isInCart(item.Codigo)) {
      let producto = cart.find((x) => x.Codigo === item.Codigo);
      if (replace === true) {
        cart[cart.indexOf(producto)].cantidad = cantidad;
      } else {
        cart[cart.indexOf(producto)].cantidad += cantidad;
      }
      setCart([...cart]);
    } else {
      setCart([...cart, { ...item, cantidad: cantidad }]);
    }
  };

  const clearCart = () => {
    setCart([]);
  };

  const removeItemsCart = (item) => {
    if (isInCart(item.Codigo)) {
      const producto = cart.find((x) => x.Codigo === item.Codigo);
      if (cart[cart.indexOf(producto)].cantidad > 1) {
        var productoH = cart.find((x) => x.Codigo === item.Codigo);
        cart[cart.indexOf(productoH)].cantidad -= 1;
        setCart([...cart]);
      } else {
        const newCart = cart.filter((x) => x.Codigo !== item.Codigo);
        setCart(newCart);
      }
    }
  };

  const removeItemComplete = (item) => {
    const newCart = cart.filter((x) => x.Codigo !== item.Codigo);
    setCart(newCart);
  }

  const isInCart = (id) => {
    return cart.some((item) => item.Codigo === id);
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        addItemsCart,
        clearCart,
        removeItemsCart,
        removeItemComplete
      }}
    >
      {children}
    </CartContext.Provider>
  );
}
