import React, { useContext } from "react";
import Card from "../Card/Card";
import EmptyCart from '../Emptycart/Emptycart';
import { CartContext } from "../../Context/cartContext";

export default function ListProductsCart() {
  const { cart } = useContext(CartContext);

  if (cart.length === 0) {
    return <EmptyCart/>
  }

  cart.sort((a, b) => a.NombreWeb.localeCompare(b.NombreWeb));

  return (
    <div style={{ margin: "20px 15px", paddingBottom: "110px" }}>
      {cart.map((item) => {
        return (
          <Card
            key={`itemN${item.row}`}
            Codigo={item.Codigo}
            Costo={item.Costo}
            Descripcion={item.Descripcion}
            Marca={item.Marca}
            NombreWeb={item.NombreWeb}
            PrecioFinal={item.PrecioFinal}
            UrlImg={item.UrlImg}
            total={true}
            cantidad={item.cantidad}
            cartCard={true}
            KeyWords={item.KeyWords}
            StockReal={item.StockReal}
          />
        );
      })}
    </div>
  );
}
