import React from "react";
import "./generic.css";

export default function Loader({ color }) {
  return (
    <div className="loader-container">
      {color === "white" ? (
        <img
          src={`${process.env.PUBLIC_URL + "/assets/img/loadingw.svg"}`}
          alt="Tecnoriego SRL"
        />
      ) : (
        <img
          src={`${process.env.PUBLIC_URL + "/assets/img/loading.svg"}`}
          alt="Tecnoriego SRL"
        />
      )}
    </div>
  );
}
