const apiKey = "AKfycbwj8fykc3SU2phqda0-5Z0JXltFafKNSRK9088EzXmygtfXfb8qtRLPOKtmqfHyAcJBxg";

const getData = async () => {
  let response = [];
  await fetch(
    `https://script.google.com/macros/s/${apiKey}/exec?action=read&table=LISTA1`
  )
    .then((response) => response.json())
    .then((data) => {
      // Data cruda
      response.push(data);

      // Categorías
      const arrayTemp = [];
      Object.entries(data.data).forEach(([key, value]) => {
        arrayTemp.push(value.Rubro);
      });
      const dataArr = new Set(arrayTemp);
      response.push([...dataArr]);

      // Menú
      const arraySubRubros = [];

      Object.entries(data.data).forEach(([key, value]) => {
        const tempArr = [value.Rubro, value.SubRubro];
        arraySubRubros.push(tempArr);
      });

      const groupedArray = Object.values(
        arraySubRubros.reduce((acc, [key, value]) => {
          if (!acc[key]) {
            acc[key] = [key, new Set()];
          }
          acc[key][1].add(value);
          return acc;
        }, {})
      ).map(([key, values]) => [key, Array.from(values)]);

      response.push(groupedArray);
    });
  return response;
};

const getCategorias = async () => {
  const response = await fetch(
    `https://script.google.com/macros/s/${apiKey}/exec?action=read&table=CATEGORIA`
  )
  return response.json();
};

const getWizard = async () => {
  const response = await fetch(
    `https://script.google.com/macros/s/${apiKey}/exec?action=read&table=WIZARD`
  )
  return response.json();
};

export {getData, getCategorias, getWizard};