import React, { createContext, useState } from "react";

export const SearchContext = createContext();

export default function SearchProvider({ children }) {
  const [search, setSearch] = useState("");

  const setSearchHandle = (event) => {
    const normalizedSearch = event.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase();
    setSearch(normalizedSearch);
  };

  return (
    <SearchContext.Provider
      value={{
        search,
        setSearch,
        setSearchHandle
      }}
    >
      {children}
    </SearchContext.Provider>
  );
}
