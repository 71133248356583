import React from 'react';
import './headertitle.css';

export default function Headertitle({title}) {
  return (
    <div className="container-header-title">
        <h2>{title}</h2>
    </div>
  )
}
