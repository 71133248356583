import React from "react";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import ListProductsOfertas from "../Components/Lists/ListProductsOfertas";

export default function Ofertas() {
  return (
    <>
      <Header
        title="Ofertas"
      ></Header>
      <ListProductsOfertas/>
      <Footer></Footer>
    </>
  );
}
