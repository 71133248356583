import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { CartContext } from "../../Context/cartContext";
import "./cartfilters.css";

export default function Cartfilters() {
  const { cart, clearCart } = useContext(CartContext);
  const [modalOpen, setModalOpen] = useState(false);

  const arrayTotales = [];
  cart.map( item => {
    return arrayTotales.push( (item.PrecioFinal * item.cantidad) )
  });
  let total = arrayTotales.reduce((acumulador, numero) => acumulador + numero, 0);
  total = total.toLocaleString(
    "es-AR",
    {
      style: "currency",
      currency: "ARS",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }
  );


  if (cart.length === 0) {
    return false;
  }

  return (
    <>
      {modalOpen && (
        <div className="modal-cart-clear">
          <div className="modal">
            <h3>¿Estás seguro que quieres vaciar tu carrito actual?</h3>
            <p>No podrás volvér a ver los productos añadidos anteriormente.</p>
            <div className="modal-footer">
              <button
                className="button button-line-blue"
                onClick={() => setModalOpen(false)}
              >
                Cancelar
              </button>
              <button
                className="button button-blue"
                onClick={() => clearCart()}
              >
                Aceptar
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="container-cart-filters">
        <div className="totals">
          Total: <span>{total}</span>
        </div>
        <button
          className="button button-line-blue"
          onClick={() => setModalOpen(true)}
        >
          Vaciar{" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="11.353"
            height="15.531"
            viewBox="0 0 11.353 15.531"
          >
            <g id="trash-can" transform="translate(-9.5 -1.2)">
              <path
                id="Trazado_31"
                data-name="Trazado 31"
                d="M19.871,3.063H17.429V2.157a.956.956,0,0,0-.957-.957H13.855a.973.973,0,0,0-.957.957v.906H10.457a.956.956,0,0,0-.957.957V5.857a.451.451,0,0,0,.453.453h.378v8.533a1.9,1.9,0,0,0,1.888,1.888h5.915a1.9,1.9,0,0,0,1.888-1.888V6.26H20.4a.451.451,0,0,0,.453-.453V3.969A.987.987,0,0,0,19.871,3.063Zm-6.092-.906a.08.08,0,0,1,.076-.076h2.618a.08.08,0,0,1,.076.076v.906H13.779ZM10.406,3.994a.08.08,0,0,1,.076-.076H19.9a.08.08,0,0,1,.076.076V5.379H10.406Zm8.71,10.824a1.01,1.01,0,0,1-1.007,1.007h-5.89a1.01,1.01,0,0,1-1.007-1.007V6.26h7.929v8.558Z"
                fill="#5c73bb"
              />
              <path
                id="Trazado_32"
                data-name="Trazado 32"
                d="M30.653,35.8a.451.451,0,0,0,.453-.453v-3.3a.453.453,0,1,0-.906,0v3.323A.446.446,0,0,0,30.653,35.8Z"
                transform="translate(-15.489 -22.748)"
                fill="#5c73bb"
              />
              <path
                id="Trazado_33"
                data-name="Trazado 33"
                d="M39.053,35.8a.451.451,0,0,0,.453-.453v-3.3a.453.453,0,1,0-.906,0v3.323A.463.463,0,0,0,39.053,35.8Z"
                transform="translate(-21.775 -22.748)"
                fill="#5c73bb"
              />
              <path
                id="Trazado_34"
                data-name="Trazado 34"
                d="M22.253,35.8a.451.451,0,0,0,.453-.453v-3.3a.453.453,0,1,0-.906,0v3.323A.431.431,0,0,0,22.253,35.8Z"
                transform="translate(-9.204 -22.748)"
                fill="#5c73bb"
              />
            </g>
          </svg>
        </button>
        {cart.length !== 0 && (
          <Link to="/checkout">
            <button className="button button-blue">Continuar</button>
          </Link>
        )}
      </div>
    </>
  );
}
