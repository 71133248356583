import React, { useState } from "react";
import {useNavigate} from 'react-router-dom';
import Menu from "../Menu/Menu";
import "./header.css";

export default function Header({ title, home, components }) {

  const navigate = useNavigate();
  const goBack = () => {
		navigate(-1);
	}

  const [menuOpen, setMenuOpen] =  useState(false);

  return (
    <div className="container-header">
      <div className="header-navigation">
        <div className="header-navigation-title">
          {home === true ? (
            <img style={{ marginRight: "15px"}}
              src={`${
                process.env.PUBLIC_URL + "/assets/img/iso-tecnoriego.svg"
              }`}
              alt="Tecnoriego SRL"
            />
          ) : (
            <button className="button-back" onClick={goBack}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22.622"
                height="15.667"
                viewBox="0 0 22.622 15.667"
              >
                <path
                  id="Layer_21"
                  data-name="Layer 21"
                  d="M24.746,15.7H8.219a.87.87,0,1,1,0-1.74H24.746a.87.87,0,0,1,0,1.74Zm-13.3,6.707a.87.87,0,0,0,0-1.235L5.1,14.828l6.35-6.341a.873.873,0,1,0-1.235-1.235L3.252,14.211a.87.87,0,0,0,0,1.235l6.959,6.959a.87.87,0,0,0,1.235,0Z"
                  transform="translate(-2.994 -6.996)"
                />
              </svg>
            </button>
          )}

          <h1>{title}</h1>
        </div>
        <button className="button-menu" onClick={() => setMenuOpen(true)}>
          <img
            src={`${process.env.PUBLIC_URL + "/assets/img/menu.svg"}`}
            alt="Menú Tecnoriego SRL"
          />
        </button>
      </div>
      <Menu handleClose={ () => setMenuOpen(false)} open={menuOpen}></Menu>
      {components}
    </div>
  );
}
