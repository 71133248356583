import React, { useContext } from "react";
import { useQuery } from "react-query";
import Card from "../Card/Card";
import Loader from "../Generic/Loader";
import { getData } from "../../Helpers/getData";
import { SearchContext } from "../../Context/SearchContext";
import { CategoryContext } from "../../Context/CategoryContext";
import { CartContext } from "../../Context/cartContext";

export default function ListProducts() {
  const { search } = useContext(SearchContext);
  const { categoria, subCategoria } = useContext(CategoryContext);
  const { cart } = useContext(CartContext);

  const { data, status } = useQuery(["sheet"], getData);

  const checkCantidad = (item) => {
    if (
      cart.length > 0 &&
      cart.find((c) => c.Codigo === item.Codigo && c.cantidad)
    ) {
      return cart.find((c) => c.Codigo === item.Codigo)?.cantidad;
    } else {
      return 0;
    }
  };

  const removeAccents = (text) => {
    return text
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
  };

  const filteredData = React.useMemo(() => {
    const searchWords = search.trim().toLowerCase().split(/\s+/);
    const categoriaEnd = categoria.replace(/\s+/g, "-").toLowerCase();
    const subCategoriaEnd = subCategoria.replace(/\s+/g, "-").toLowerCase();

    return data?.[0]?.data
      .filter((item) => {
        const itemWords = [
          removeAccents(item.NombreWeb.toLowerCase()),
          removeAccents(item.Marca.toLowerCase()),
          removeAccents(item.KeyWords.toLowerCase()),
        ].join(" ");
        return searchWords.every((word) => itemWords.includes(word));
      })
      .filter(
        (item) =>
          (categoria === "" ||
            item.Rubro.replace(/\s+/g, "-").toLowerCase() === categoriaEnd) &&
          (subCategoria === "" ||
            item.SubRubro.replace(/\s+/g, "-").toLowerCase() ===
              subCategoriaEnd) &&
          item.Habilitado === "SI"
      )
      .sort((a, b) => a.NombreWeb.localeCompare(b.NombreWeb))
      .map((item, index) => ({
        Codigo: item.Codigo,
        Costo: item.Costo,
        Descripcion: item.Descripcion,
        Habilitado: item.Habilitado,
        Insta: item.Insta,
        Marca: item.Marca,
        Moneda: item.Moneda,
        NombreEnSistema: item.NombreEnSistema,
        NombreWeb: item.NombreWeb,
        PorcentajeIVA: item.PorcentajeIVA,
        PrecioFinal: item.PrecioFinal,
        PrecioSinIVA: item.PrecioSinIVA,
        Rubro: item.Rubro,
        SubRubro: item.SubRubro,
        UrlImg: item.UrlImg,
        UtilidadMay: item.UtilidadMay,
        UtilidadMin: item.UtilidadMin,
        row: item.row,
        cantidad: checkCantidad(item),
        KeyWords: item.KeyWords,
        StockReal: item.StockReal,
      }));
  }, [data, search, categoria, subCategoria, cart]);

  if (status === "loading") {
    return <Loader />;
  }

  if (status === "error") {
    return <p>Ocurrió un error al consultar el servidor.</p>;
  }

  return (
    <div style={{ margin: "20px 15px", paddingBottom: "110px" }}>
      {filteredData.map((item) => {
        if (item.Rubro !== "") {
          return (
            <Card
              key={`itemN${item.Codigo}`}
              Codigo={item.Codigo}
              Costo={item.Costo}
              Descripcion={item.Descripcion}
              Habilitado={item.Habilitado}
              Insta={item.Insta}
              Marca={item.Marca}
              Moneda={item.Moneda}
              NombreEnSistema={item.NombreEnSistema}
              NombreWeb={item.NombreWeb}
              PorcentajeIVA={item.PorcentajeIVA}
              PrecioFinal={item.PrecioFinal}
              PrecioSinIVA={item.PrecioSinIVA}
              Rubro={item.Rubro}
              SubRubro={item.SubRubro}
              UrlImg={item.UrlImg}
              UtilidadMay={item.UtilidadMay}
              UtilidadMin={item.UtilidadMin}
              cantidad={item.cantidad}
              KeyWords={item.KeyWords}
              StockReal={item.StockReal}
              row={item.row}
            />
          );
        } else {
          return false;
        }
      })}
    </div>
  );
}
