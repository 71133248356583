import React from "react";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import Filtros from "../Components/Filtros/Filtros";
import Search from "../Components/Search/Search";
import ListProducts from "../Components/Lists/ListProducts";


export default function home() {
  return (
    <>
      <Header
        title="Catálogo"
        components={
          <>
            <Filtros />
            <Search />
          </>
        }
      ></Header>
      <ListProducts/>
      <Footer active="catalogo"></Footer>
    </>
  );
}
