import React, { useContext } from "react";
import { CartContext } from "../../Context/cartContext";
import "./footer.css";
import { Link } from "react-router-dom";

export default function Footer({ active }) {
  const { cart } = useContext(CartContext);

  return (
    <div className="container-footer">
      <div className="container-buttons">
        <Link to="/inicio">
          <button
            className={`buttons-footer ${active === "home" ? "active" : ""}`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="27.146"
              height="27.135"
              viewBox="0 0 27.146 27.135"
            >
              <path
                id="Trazado_94"
                data-name="Trazado 94"
                d="M25.879,28.36H19.929a2.445,2.445,0,0,1-2.424-2.424V21.089a.884.884,0,0,0-.881-.881H12.966a.884.884,0,0,0-.881.881v4.8a2.445,2.445,0,0,1-2.424,2.424H3.624A2.445,2.445,0,0,1,1.2,25.893V10.512a1.923,1.923,0,0,1,.925-1.631L13.76,1.522a1.951,1.951,0,0,1,2.071,0L27.465,8.882a1.882,1.882,0,0,1,.881,1.631V25.848A2.474,2.474,0,0,1,25.879,28.36Zm-12.956-9.7H16.58A2.445,2.445,0,0,1,19,21.089v4.8a.884.884,0,0,0,.881.881h5.993a.884.884,0,0,0,.881-.881V10.557a.544.544,0,0,0-.176-.353L14.994,2.845a.329.329,0,0,0-.4,0L2.963,10.2a.4.4,0,0,0-.176.353v15.38a.884.884,0,0,0,.881.881H9.661a.884.884,0,0,0,.881-.881V21.089A2.379,2.379,0,0,1,12.922,18.665Z"
                transform="translate(-1.2 -1.225)"
                fill="#103a94"
              />
            </svg>
          </button>
        </Link>
        <Link to="/catalogo">
          <button
            className={`buttons-footer ${
              active === "catalogo" ? "active" : ""
            }`}
          >
            <svg
              id="grid-alt"
              xmlns="http://www.w3.org/2000/svg"
              width="27.597"
              height="26.97"
              viewBox="0 0 27.597 26.97"
            >
              <g id="Grupo_1" data-name="Grupo 1" transform="translate(0 0)">
                <path
                  id="Trazado_13"
                  data-name="Trazado 13"
                  d="M9.488,12.787H3.8a2.6,2.6,0,0,1-2.6-2.6V4.5A2.6,2.6,0,0,1,3.8,1.9h5.69a2.6,2.6,0,0,1,2.6,2.6v5.69A2.6,2.6,0,0,1,9.488,12.787ZM3.8,3.468A1.042,1.042,0,0,0,2.768,4.5v5.69a1.042,1.042,0,0,0,1.03,1.03h5.69a1.042,1.042,0,0,0,1.03-1.03V4.5a1.042,1.042,0,0,0-1.03-1.03H3.8Z"
                  transform="translate(-1.2 -1.9)"
                  fill="#0a4392"
                />
              </g>
              <g
                id="Grupo_2"
                data-name="Grupo 2"
                transform="translate(16.711 0)"
              >
                <path
                  id="Trazado_14"
                  data-name="Trazado 14"
                  d="M46.788,12.787H41.1a2.6,2.6,0,0,1-2.6-2.6V4.5a2.6,2.6,0,0,1,2.6-2.6h5.69a2.6,2.6,0,0,1,2.6,2.6v5.69A2.6,2.6,0,0,1,46.788,12.787ZM41.1,3.468a1.042,1.042,0,0,0-1.03,1.03v5.69a1.042,1.042,0,0,0,1.03,1.03h5.69a1.042,1.042,0,0,0,1.03-1.03V4.5a1.042,1.042,0,0,0-1.03-1.03H41.1Z"
                  transform="translate(-38.5 -1.9)"
                  fill="#0a4392"
                />
              </g>
              <g
                id="Grupo_3"
                data-name="Grupo 3"
                transform="translate(0 16.084)"
              >
                <path
                  id="Trazado_15"
                  data-name="Trazado 15"
                  d="M9.488,48.687H3.8a2.6,2.6,0,0,1-2.6-2.6V40.4a2.6,2.6,0,0,1,2.6-2.6h5.69a2.6,2.6,0,0,1,2.6,2.6v5.69A2.6,2.6,0,0,1,9.488,48.687ZM3.8,39.368a1.042,1.042,0,0,0-1.03,1.03v5.69a1.042,1.042,0,0,0,1.03,1.03h5.69a1.042,1.042,0,0,0,1.03-1.03V40.4a1.042,1.042,0,0,0-1.03-1.03Z"
                  transform="translate(-1.2 -37.8)"
                  fill="#0a4392"
                />
              </g>
              <g
                id="Grupo_4"
                data-name="Grupo 4"
                transform="translate(16.711 16.084)"
              >
                <path
                  id="Trazado_16"
                  data-name="Trazado 16"
                  d="M46.788,48.687H41.1a2.6,2.6,0,0,1-2.6-2.6V40.4a2.6,2.6,0,0,1,2.6-2.6h5.69a2.6,2.6,0,0,1,2.6,2.6v5.69A2.6,2.6,0,0,1,46.788,48.687ZM41.1,39.368a1.042,1.042,0,0,0-1.03,1.03v5.69a1.042,1.042,0,0,0,1.03,1.03h5.69a1.042,1.042,0,0,0,1.03-1.03V40.4a1.042,1.042,0,0,0-1.03-1.03Z"
                  transform="translate(-38.5 -37.8)"
                  fill="#0a4392"
                />
              </g>
            </svg>
          </button>
        </Link>
        <Link to="/carrito">
          <button
            className={`buttons-footer ${active === "cart" ? "active" : ""}`}
          >
            {cart.length <= 0 ? (
              <svg
                id="cart"
                xmlns="http://www.w3.org/2000/svg"
                width="24.313"
                height="27.1"
                viewBox="0 0 24.313 27.1"
              >
                <path
                  id="Trazado_4"
                  data-name="Trazado 4"
                  d="M36.929,48.2a3.218,3.218,0,1,0,3.129,3.217A3.132,3.132,0,0,0,36.929,48.2Zm0,4.891a1.677,1.677,0,1,1,1.586-1.674A1.6,1.6,0,0,1,36.929,53.091Z"
                  transform="translate(-20.899 -27.534)"
                  fill="#0a4392"
                />
                <path
                  id="Trazado_5"
                  data-name="Trazado 5"
                  d="M14.229,48.2a3.218,3.218,0,1,0,3.129,3.217A3.132,3.132,0,0,0,14.229,48.2Zm0,4.891a1.677,1.677,0,1,1,1.586-1.674A1.6,1.6,0,0,1,14.229,53.091Z"
                  transform="translate(-8.202 -27.534)"
                  fill="#0a4392"
                />
                <path
                  id="Trazado_6"
                  data-name="Trazado 6"
                  d="M28,1.3h-2.82a1.33,1.33,0,0,0-1.322,1.146l-.793,5.817H5.747a1.17,1.17,0,0,0-.969.485A1.309,1.309,0,0,0,4.557,9.8v.044l2.688,8.328a1.243,1.243,0,0,0,1.234.925h13a1.9,1.9,0,0,0,1.895-1.674L25.4,2.8h2.644a.79.79,0,0,0,.793-.793A.759.759,0,0,0,28,1.3ZM21.786,17.207a.424.424,0,0,1-.4.353H8.611L6.1,9.8H22.8Z"
                  transform="translate(-4.523 -1.3)"
                  fill="#0a4392"
                />
              </svg>
            ) : (
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25.265"
                  height="27.561"
                  viewBox="0 0 25.265 27.561"
                >
                  <g id="cart-full" transform="translate(-3.823 -1.3)">
                    <path
                      id="Trazado_1"
                      data-name="Trazado 1"
                      d="M33.127,48.2a3.272,3.272,0,1,0,3.227,3.272A3.252,3.252,0,0,0,33.127,48.2Zm0,4.975a1.665,1.665,0,0,1-1.658-1.7,1.693,1.693,0,0,1,1.658-1.7,1.7,1.7,0,0,1,0,3.406Z"
                      transform="translate(-14.39 -25.882)"
                      fill="#103a94"
                    />
                    <path
                      id="Trazado_2"
                      data-name="Trazado 2"
                      d="M13.927,48.2a3.272,3.272,0,1,0,3.227,3.272A3.252,3.252,0,0,0,13.927,48.2Zm0,4.975a1.665,1.665,0,0,1-1.658-1.7,1.693,1.693,0,0,1,1.658-1.7,1.7,1.7,0,0,1,0,3.406Z"
                      transform="translate(-3.795 -25.882)"
                      fill="#103a94"
                    />
                    <path
                      id="Trazado_3"
                      data-name="Trazado 3"
                      d="M28.282,1.3h-2.42A1.981,1.981,0,0,0,23.89,3l-.762,5.423H20.8V4.84a1.2,1.2,0,0,0-1.21-1.21h-3.54a1.186,1.186,0,0,0-1.165-1.165h-3.9a1.255,1.255,0,0,0-1.21,1.3V4.84H6.233a1.168,1.168,0,0,0-1.21,1.165v2.42a1.308,1.308,0,0,0-.941.493,1.331,1.331,0,0,0-.224,1.076v.045l2.779,8.47a1.3,1.3,0,0,0,1.255.941h12.5a3.181,3.181,0,0,0,3.137-2.734L25.459,3.227a.431.431,0,0,1,.4-.359h2.42a.8.8,0,0,0,.807-.807A.766.766,0,0,0,28.282,1.3ZM19.229,8.381H16.047V5.2h3.182ZM11.342,4.034h3.182V8.381H11.342V4.034ZM6.591,6.409H9.773V8.381H6.591ZM21.963,16.492a1.631,1.631,0,0,1-1.569,1.389H8.115l-2.6-7.932H22.949Z"
                      transform="translate(0 0)"
                      fill="#103a94"
                    />
                  </g>
                </svg>
                <div className="cart-total-count">{cart.length}</div>
              </div>
            )}
          </button>
        </Link>
      </div>
    </div>
  );
}
