import React from "react";
import { Link } from "react-router-dom";
import "./emptycart.css";

export default function Emptycart() {
  return (
    <div className="container-empty-cart">
      <svg
        id="Componente_4_1"
        data-name="Componente 4 – 1"
        xmlns="http://www.w3.org/2000/svg"
        width="60.313"
        height="61.1"
        viewBox="0 0 60.313 61.1"
      >
        <ellipse
          id="Elipse_13"
          data-name="Elipse 13"
          cx="30.157"
          cy="30.55"
          rx="30.157"
          ry="30.55"
          fill="#a1b9e1"
        />
        <g id="cart" transform="translate(18 16.612)">
          <path
            id="Trazado_4"
            data-name="Trazado 4"
            d="M36.929,48.2a3.218,3.218,0,1,0,3.129,3.217A3.132,3.132,0,0,0,36.929,48.2Zm0,4.891a1.677,1.677,0,1,1,1.586-1.674A1.6,1.6,0,0,1,36.929,53.091Z"
            transform="translate(-20.899 -27.534)"
            fill="#fff"
          />
          <path
            id="Trazado_5"
            data-name="Trazado 5"
            d="M14.229,48.2a3.218,3.218,0,1,0,3.129,3.217A3.132,3.132,0,0,0,14.229,48.2Zm0,4.891a1.677,1.677,0,1,1,1.586-1.674A1.6,1.6,0,0,1,14.229,53.091Z"
            transform="translate(-8.202 -27.534)"
            fill="#fff"
          />
          <path
            id="Trazado_6"
            data-name="Trazado 6"
            d="M28,1.3h-2.82a1.33,1.33,0,0,0-1.322,1.146l-.793,5.817H5.747a1.17,1.17,0,0,0-.969.485A1.309,1.309,0,0,0,4.557,9.8v.044l2.688,8.328a1.243,1.243,0,0,0,1.234.925h13a1.9,1.9,0,0,0,1.895-1.674L25.4,2.8h2.644a.79.79,0,0,0,.793-.793A.759.759,0,0,0,28,1.3ZM21.786,17.207a.424.424,0,0,1-.4.353H8.611L6.1,9.8H22.8Z"
            transform="translate(-4.523 -1.3)"
            fill="#fff"
          />
        </g>
      </svg>
      <h2>Tu carrito se encuentra vacío</h2>
      <Link to="/inicio">
        <button className="button button-blue">Ir al inicio</button>
      </Link>
    </div>
  );
}
