import React from "react";
import "./menu.css";
import Navigation from "./Navigation";

export default function Menu({ open, handleClose }) {
  if (open === true) {
    return (
      <div className="container-menu">
        <div className="menu-header">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="29.554"
            height="41.824"
            viewBox="0 0 29.554 41.824"
          >
            <g
              id="Grupo_37"
              data-name="Grupo 37"
              transform="translate(-173.666 -141)"
            >
              <path
                id="Trazado_36"
                data-name="Trazado 36"
                d="M116.085,54.957c-.206-.586-.447-1.178-.72-1.764-.062-.129-.118-.257-.18-.381-.262-.54-.561-1.075-.874-1.589s-.684-1.064-1.085-1.6a.074.074,0,0,0-.093-.026.08.08,0,0,0-.046.087,8.9,8.9,0,0,1,.051,2.931c-.01.082-.026.165-.041.247a8.058,8.058,0,0,1-.329,1.28,8.94,8.94,0,0,1-3.451,4.536.081.081,0,0,0-.026.041,21.869,21.869,0,0,1-14.466,3.713.087.087,0,0,0-.082.046.089.089,0,0,0,.021.093,14.992,14.992,0,0,0,9.508,3.343h.144a14.773,14.773,0,0,0,11.231-5.029,15.207,15.207,0,0,0,1.388-1.9.063.063,0,0,0,.01-.051,19.633,19.633,0,0,0-.967-3.98"
                transform="translate(84.096 116.913)"
                fill="#a4bde3"
              />
              <path
                id="Trazado_37"
                data-name="Trazado 37"
                d="M122.832,21.953A15.6,15.6,0,0,0,121.67,19.5c-.206-.37-.442-.746-.7-1.121C119.747,16.6,112.475,5.06,109.405.185A.381.381,0,0,0,109.076,0h0a.381.381,0,0,0-.329.185l-.792,1.26c-.813,1.286-1.975,3.137-3.25,5.153a.073.073,0,0,0-.01.031,20.246,20.246,0,0,0,.92,9.74,18.057,18.057,0,0,0,.72,1.764c.072.159.149.324.242.5a.083.083,0,0,0,.093.041,7.4,7.4,0,0,1,.879-.2,9.007,9.007,0,0,1,6.469,1.363c.129.087.257.175.381.273a21.442,21.442,0,0,1,5.364,5.533c.355.535.669,1.049.962,1.579a20.044,20.044,0,0,1,1.1,2.3c.216.519.411,1.07.591,1.646.144.468.273.931.375,1.368a.082.082,0,0,0,.072.062h0a.077.077,0,0,0,.072-.051,12.019,12.019,0,0,0,.427-1.322,13.652,13.652,0,0,0,.329-1.568,15.358,15.358,0,0,0-.854-7.693"
                transform="translate(79.37 141)"
                fill="#fbfaf9"
              />
              <path
                id="Trazado_38"
                data-name="Trazado 38"
                d="M98.085,44.888A8.838,8.838,0,0,1,90.577,36.1a8.94,8.94,0,0,1,5.009-8,.091.091,0,0,0,.041-.046.125.125,0,0,0,0-.062c-.226-.458-.437-.921-.622-1.388-.211-.525-.411-1.075-.586-1.646a20.023,20.023,0,0,1-.972-6.593.077.077,0,0,0-.057-.077.071.071,0,0,0-.087.031c-1.471,2.314-3.127,4.932-4.3,6.762-.514.8-.915,1.409-1.193,1.826-.082.129-.154.231-.216.319l-.015.021a15.247,15.247,0,0,0-1.4,15.433,14.816,14.816,0,0,0,1.82,2.782.054.054,0,0,0,.046.026A20.284,20.284,0,0,0,98.1,45.037a.079.079,0,0,0,.057-.082.077.077,0,0,0-.067-.072"
                transform="translate(88.977 132.114)"
                fill="#a4bde3"
              />
            </g>
          </svg>
          <button className="button-close-menu" onClick={() => handleClose()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="44.91"
              height="44.91"
              viewBox="0 0 44.91 44.91"
            >
              <path
                id="plus"
                d="M32.028,16.2H18.006V2.128a.924.924,0,0,0-.928-.928.882.882,0,0,0-.876.928V16.2H2.128a.924.924,0,0,0-.928.928.882.882,0,0,0,.928.876H16.2V32.028a.928.928,0,0,0,1.856,0V18.006H32.028a.924.924,0,0,0,.928-.928A.882.882,0,0,0,32.028,16.2Z"
                transform="translate(22.455 -1.697) rotate(45)"
                fill="#fff"
              />
            </svg>
          </button>
        </div>

        <Navigation handleClose={() => handleClose()}/>
      </div>
    );
  }
}
