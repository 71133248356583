import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import { getData } from "../../Helpers/getData";
import Loader from "../Generic/Loader";
import Collapsible from "react-collapsible";
import { CategoryContext } from "../../Context/CategoryContext";

export default function Navigation({ handleClose }) {
  const { data: menu, status } = useQuery("sheet", getData);
  const { setCategoria, setSubCategoria } = useContext(CategoryContext);

  let sortedDataMenu = [];

  if (status === "loading") {
    return <Loader color="white" />;
  }

  if (status === "error") {
    return (
      <p>
        Ocurrió un error al conectar con el servidor. Intenta luego nuevamente
      </p>
    );
  }

  let dataOrdenada = [];

  if (status === 'success'){
    dataOrdenada = menu[2].sort((a, b) => {
      // Ordenar por el primer elemento del array
      if (a[0] < b[0]) {
        return -1;
      }
      if (a[0] > b[0]) {
        return 1;
      }
      // Si los primeros elementos son iguales, ordenar por el segundo elemento
      return a[1][0].localeCompare(b[1][0]);
    });
  }

  const handleSetAction = (cat, subcat) => {
    handleClose();
    setCategoria(cat);
    if (subcat) {
      setSubCategoria(subcat);
    }
  };

  return (
    <nav className="container-nav">
      <ul>
        <Link to="/inicio">
          <li className="text level1">Inicio</li>
        </Link>
        <li className="text level1">
          <Collapsible transitionTime={100} trigger="Catálogo">
            <ul>
              {dataOrdenada.map((item) => {
                const itemString = item[0];
                const newStringItem = itemString
                  .replace(/\s+/g, "-")
                  .toLowerCase();

                if (itemString !== "") {
                  return (
                    <li className="text level2" key={newStringItem}>
                      <Collapsible transitionTime={100} trigger={item[0]}>
                        <ul>
                          <Link
                            onClick={() => handleSetAction(newStringItem, "")}
                            to={"/catalogo"}
                          >
                            <li className="text level3">
                              Todo en {itemString}
                            </li>
                          </Link>
                          {item[1].map((subitem) => {
                            const string = subitem;
                            const newString = string
                              .replace(/\s+/g, "-")
                              .toLowerCase();
                            return (
                              <Link
                                onClick={() =>
                                  handleSetAction(newStringItem, newString)
                                }
                                to={`/catalogo`}
                                key={newString}
                              >
                                <li className="text level3">{subitem}</li>
                              </Link>
                            );
                          })}
                        </ul>
                      </Collapsible>
                    </li>
                  );
                } else{
                  return false;
                }
              })}
            </ul>
          </Collapsible>
        </li>
        <Link to="/carrito">
          <li className="text level1">Carrito</li>
        </Link>
      </ul>
    </nav>
  );
}
