import React, { useContext } from "react";
import { useQuery } from "react-query";
import { CategoryContext } from "../../Context/CategoryContext";
import { getData } from "../../Helpers/getData";

import "./filtros.css";

export default function Filtros() {
  const { categoria, setCategoria, subCategoria, setSubCategoria } =
    useContext(CategoryContext);
  const { data, status } = useQuery("sheet", getData);

  let sortedData1 = [];
  let sortedData2 = [];

  const handleSetCategoriaPadre = (e) => {
    setCategoria(e);
    setSubCategoria("");
  };

  if (status === "loading") {
    return false;
  }

  if (status === "success") {
    sortedData1 = data[1].sort((a, b) => a.localeCompare(b));
    sortedData2 = data[2].map(([cat, subs]) => [
      cat,
      subs.sort((a, b) => a.localeCompare(b)),
    ]);
  }

  return (
    <>
      <select
        className="select-filtros mb-4 mt-4"
        name="categoria"
        id="categoria"
        onChange={(e) => handleSetCategoriaPadre(e.target.value)}
        value={categoria || ""}
      >
        <option value="">Todos los rubros</option>
        {sortedData1.map((item) => {
          const string = item;
          const newString = string.replace(/\s+/g, "-").toLowerCase();
          const isSelected = newString === categoria;
          if (item === "") {
            return false;
          }

          return (
            <option key={newString} value={newString} selected={isSelected}>
              {string}
            </option>
          );
        })}
      </select>
      {categoria !== "" && (
        <select
          className="select-filtros"
          name="subcategoria"
          id="subcategoria"
          value={subCategoria || ""}
          onChange={(e) => setSubCategoria(e.target.value)}
        >
          <option value="" selected>
            Selecciona un sub rubro
          </option>
          {sortedData2.map((item) => {
            const string = item[0];
            const newString = string.replace(/\s+/g, "-").toLowerCase();
            if (newString === categoria) {
              return item[1].map((sub) => {
                const subString = sub;
                const newSubString = subString
                  .replace(/\s+/g, "-")
                  .toLowerCase();
                return (
                  <option
                    key={newSubString}
                    value={newSubString}
                    selected={subString}
                  >
                    {subString}
                  </option>
                );
              });
            }
            return null;
          })}
        </select>
      )}
    </>
  );
}
