import React, { useContext, useEffect } from "react";
import Checkout from "../Components/Checkout/Checkout";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import Headertitle from "../Components/Headertitle/Headertitle";
import { SearchContext } from "../Context/SearchContext";

export default function CheckoutPage() {
  const { setSearch } = useContext(SearchContext);

  useEffect(() => {
    setSearch("");
  }, [setSearch]);
  return (
    <>
      <Header
        title="Carrito 2/2"
        components={<Headertitle title="Envíanos tu pedido" />}
      />
      <div style={{ margin: "20px 15px", paddingBottom: "110px" }}>
        <Checkout />
      </div>
      <Footer active="cart"></Footer>
    </>
  );
}
