import React, { useContext, useEffect } from "react";
import Banneroferta from "../Components/Banner/Banneroferta";
import Categorias from "../Components/Categorias/Categorias";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import Search from "../Components/Search/Search";
import { SearchContext } from "../Context/SearchContext";

export default function Home() {
  const { setSearch } = useContext(SearchContext);

  useEffect(() => {
    setSearch("");
  }, [setSearch]);

  return (
    <>
      <Header home={true} title="Tecnoriego SRL" components={<Search />} />
      <Categorias />
      <Banneroferta />
      <Footer active="home" />
    </>
  );
}
