import "./App.css";
import { HashRouter, Route, Routes } from "react-router-dom";

// import Intro from "./Routes/Intro";
import Home from "./Routes/Home";
import Catalogo from "./Routes/Catalogo";
import Cart from "./Routes/Cart";
import Checkout from "./Routes/Checkout";
import SearchProvider from "./Context/SearchContext";
import CartProvider from "./Context/cartContext";
import CategoryProvider from "./Context/CategoryContext";
import Ofertas from "./Routes/Ofertas";

function App() {
  return (
    <div className="container-main">
      <CategoryProvider>
        <CartProvider>
          <SearchProvider>
            <HashRouter>
              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/inicio" element={<Home />} />
                <Route exact path="/catalogo" element={<Catalogo />} />
                <Route exact path="/carrito" element={<Cart />} />
                <Route exact path="/checkout" element={<Checkout />} />
                <Route exact path="/ofertas" element={<Ofertas />} />
              </Routes>
            </HashRouter>
          </SearchProvider>
        </CartProvider>
      </CategoryProvider>
    </div>
  );
}

export default App;
