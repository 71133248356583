import React, { useState, useContext } from "react";
import { CartContext } from "../../Context/cartContext";
import "./card.css";

export default function Card({
  total,
  Codigo,
  // Costo,
  Descripcion,
  // Habilitado,
  // Insta,
  Marca,
  // Moneda,
  NombreEnSistema,
  NombreWeb,
  // PorcentajeIVA,
  PrecioFinal,
  // PrecioSinIVA,
  Rubro,
  SubRubro,
  UrlImg,
  // UtilidadMay,
  // UtilidadMin,
  // row,
  cantidad,
  KeyWords,
  StockReal,
  cartCard,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [countAdd, setCountAdd] = useState(0);
  const [modalInputOpen, setModalInputOpen] = useState(false);
  const [formData, setFormData] = useState(null);

  const { addItemsCart, removeItemsCart, removeItemComplete } =
    useContext(CartContext);

  let keywordsArray = [];
  let keywords = KeyWords;
  if (keywords !== "") {
    keywordsArray = keywords.split(", ");
  }

  let cardStyle = {};

  const precioFormateado = PrecioFinal.toLocaleString("es-AR", {
    style: "currency",
    currency: "ARS",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const precioCantidadFormateado = (PrecioFinal * cantidad).toLocaleString(
    "es-AR",
    {
      style: "currency",
      currency: "ARS",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }
  );

  if (cantidad > 0) {
    cardStyle = { border: "solid 2px #5c73bb" };
  }

  const handleChangeModalInput = (e) => {
    setFormData(e.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleSubmitModalInput();
  };

  const handleRemove = () => {
    setCountAdd(0);
    removeItemComplete({
      Codigo,
      NombreWeb,
      Marca,
      UrlImg,
      PrecioFinal,
      Descripcion,
      Rubro,
      SubRubro,
      KeyWords,
      StockReal,
    });
  };

  const handleSubmitModalInput = () => {
    setModalInputOpen(false);
    setCountAdd(parseInt(formData));
    addItemsCart(
      {
        Codigo,
        NombreWeb,
        Marca,
        UrlImg,
        PrecioFinal,
        Descripcion,
        Rubro,
        SubRubro,
        cantidad: parseInt(formData),
        KeyWords,
        StockReal
      },
      parseInt(formData),
      true
    );
  };

  const handleAdd = (action) => {
    if (action === "add") {
      setCountAdd(countAdd + 1);
      addItemsCart(
        {
          Codigo,
          NombreWeb,
          Marca,
          UrlImg,
          PrecioFinal,
          Descripcion,
          Rubro,
          SubRubro,
          cantidad: countAdd,
          KeyWords,
          StockReal
        },
        1
      );
      // console.log(cart);
    }

    if (action === "remove") {
      if (countAdd !== 0) {
        setCountAdd(countAdd - 1);
      }
      removeItemsCart({
        Codigo,
        NombreWeb,
        Marca,
        UrlImg,
        PrecioFinal,
        Descripcion,
        Rubro,
        SubRubro,
        cantidad: countAdd,
        KeyWords,
        StockReal
      });
      // console.log(cart);
    }
  };

  return (
    <>
      {modalInputOpen === true && (
        <form onSubmit={handleSubmit}>
          <div className="container-modal-card">
            <div className="modal-card">
              <div className="modal-card-header">
                <button
                  className="button-close-modal"
                  onClick={() => setModalInputOpen(false)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="44.91"
                    height="44.91"
                    viewBox="0 0 44.91 44.91"
                  >
                    <path
                      id="plus"
                      d="M32.028,16.2H18.006V2.128a.924.924,0,0,0-.928-.928.882.882,0,0,0-.876.928V16.2H2.128a.924.924,0,0,0-.928.928.882.882,0,0,0,.928.876H16.2V32.028a.928.928,0,0,0,1.856,0V18.006H32.028a.924.924,0,0,0,.928-.928A.882.882,0,0,0,32.028,16.2Z"
                      transform="translate(22.455 -1.697) rotate(45)"
                      fill="#495a76"
                    />
                  </svg>
                </button>
              </div>
              <div className="modal-card-content">
                <p>Indica la cantidad de productos que deseas agregar</p>

                <input
                  name="cantidadModal"
                  type="number"
                  onChange={handleChangeModalInput}
                  value={formData}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      handleSubmit(event);
                    }
                  }}
                />

                <div className="modal-footer">
                  <button
                    className="button button-line-blue"
                    onClick={() => setModalInputOpen(false)}
                  >
                    Cancelar
                  </button>
                  <button
                    type="submit"
                    onClick={() => handleSubmitModalInput()}
                    className="button button-blue"
                  >
                    Agregar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
      {modalOpen === true && (
        <div className="container-modal-card">
          <div className="modal-card">
            <div className="modal-card-header">
              <button
                className="button-close-modal"
                onClick={() => setModalOpen(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="44.91"
                  height="44.91"
                  viewBox="0 0 44.91 44.91"
                >
                  <path
                    id="plus"
                    d="M32.028,16.2H18.006V2.128a.924.924,0,0,0-.928-.928.882.882,0,0,0-.876.928V16.2H2.128a.924.924,0,0,0-.928.928.882.882,0,0,0,.928.876H16.2V32.028a.928.928,0,0,0,1.856,0V18.006H32.028a.924.924,0,0,0,.928-.928A.882.882,0,0,0,32.028,16.2Z"
                    transform="translate(22.455 -1.697) rotate(45)"
                    fill="#495a76"
                  />
                </svg>
              </button>
            </div>
            <div className="modal-card-content">
              {UrlImg !== "" ? (
                <img
                  src={`${!UrlImg.includes("http") ? `https://file.tecnoriegosrl.com.ar/${UrlImg}` : UrlImg}`}
                  alt="Tecnoriego SRL"
                />
              ) : (
                <img
                  src={`${
                    process.env.PUBLIC_URL + "/assets/img/nodisponible.png"
                  }`}
                  alt="Tecnoriego SRL"
                />
              )}
              {NombreWeb !== "" && <h2>{NombreWeb}</h2>}
              {Marca !== "" && <p>Marca: {Marca}</p>}
              {Descripcion !== "" && <p>{Descripcion}</p>}
            </div>
          </div>
        </div>
      )}
      <div className="container-card" style={cardStyle}>
        <div className="container-content">
          <div className="container-content-left">
            {UrlImg !== "" ? (
              <img
                src={`${!UrlImg.includes("http") ? `https://file.tecnoriegosrl.com.ar/${UrlImg}` : UrlImg}`}
                alt="Tecnoriego SRL"
              />
            ) : (
              <img
                src={`${
                  process.env.PUBLIC_URL + "/assets/img/nodisponible.png"
                }`}
                alt="Tecnoriego SRL"
              />
            )}
            <div>
            {NombreWeb !== "" && <h2>{NombreWeb}</h2>}
            {Descripcion !== "" && (
              <p>
                {Descripcion.length >= 100
                  ? Descripcion.slice(0, 100) + "..."
                  : Descripcion}
              </p>
            )}

            {Marca !== "" && <p>Marca: {Marca}</p>}
            {Marca && (
              <div style={{ display: "none" }}>
                Marca: {Marca.replace(/\s+/g, "-").toLowerCase()}
              </div>
            )}
            {Rubro && (
              <div style={{ display: "none" }}>
                Rubro: {Rubro.replace(/\s+/g, "-").toLowerCase()}
              </div>
            )}
            {Rubro && (
              <div style={{ display: "none" }}>
                Subrubros: {Rubro.replace(/\s+/g, "-").toLowerCase()}
              </div>
            )}
            {keywordsArray.length > 0 &&
              keywordsArray.map((item) => {
                return (
                  <div key={item} style={{ display: "none" }}>
                    {item}
                  </div>
                );
              })}
            {PrecioFinal !== "" && (
              <span>
                {precioFormateado}{" "}
                {parseInt(StockReal) > 0 ? <strong className="stock">En stock</strong> : <strong className="sinstock">Sin stock</strong>}
              </span>
            )}
            </div>
          </div>
          {cantidad > 0 && (
            <div>
            <button onClick={() => handleRemove()} className="remove-product">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11.353"
                height="15.531"
                viewBox="0 0 11.353 15.531"
              >
                <g id="trash-can" transform="translate(-9.5 -1.2)">
                  <path
                    id="Trazado_31"
                    data-name="Trazado 31"
                    d="M19.871,3.063H17.429V2.157a.956.956,0,0,0-.957-.957H13.855a.973.973,0,0,0-.957.957v.906H10.457a.956.956,0,0,0-.957.957V5.857a.451.451,0,0,0,.453.453h.378v8.533a1.9,1.9,0,0,0,1.888,1.888h5.915a1.9,1.9,0,0,0,1.888-1.888V6.26H20.4a.451.451,0,0,0,.453-.453V3.969A.987.987,0,0,0,19.871,3.063Zm-6.092-.906a.08.08,0,0,1,.076-.076h2.618a.08.08,0,0,1,.076.076v.906H13.779ZM10.406,3.994a.08.08,0,0,1,.076-.076H19.9a.08.08,0,0,1,.076.076V5.379H10.406Zm8.71,10.824a1.01,1.01,0,0,1-1.007,1.007h-5.89a1.01,1.01,0,0,1-1.007-1.007V6.26h7.929v8.558Z"
                    fill="#5c73bb"
                  />
                  <path
                    id="Trazado_32"
                    data-name="Trazado 32"
                    d="M30.653,35.8a.451.451,0,0,0,.453-.453v-3.3a.453.453,0,1,0-.906,0v3.323A.446.446,0,0,0,30.653,35.8Z"
                    transform="translate(-15.489 -22.748)"
                    fill="#5c73bb"
                  />
                  <path
                    id="Trazado_33"
                    data-name="Trazado 33"
                    d="M39.053,35.8a.451.451,0,0,0,.453-.453v-3.3a.453.453,0,1,0-.906,0v3.323A.463.463,0,0,0,39.053,35.8Z"
                    transform="translate(-21.775 -22.748)"
                    fill="#5c73bb"
                  />
                  <path
                    id="Trazado_34"
                    data-name="Trazado 34"
                    d="M22.253,35.8a.451.451,0,0,0,.453-.453v-3.3a.453.453,0,1,0-.906,0v3.323A.431.431,0,0,0,22.253,35.8Z"
                    transform="translate(-9.204 -22.748)"
                    fill="#5c73bb"
                  />
                </g>
              </svg>
            </button>
            </div>
          )}
        </div>
        <div className="footer-content">
          <button
            className="button button-line-blue"
            onClick={() => setModalOpen(true)}
          >
            + info
          </button>
          {cantidad > 0 && (
            <div className="container-subtotal">
              <span className="total">Total:</span>{" "}
              <span className="precio">{precioCantidadFormateado}</span>
            </div>
          )}

          {PrecioFinal !== "" && PrecioFinal !== 0 && (
            <div className="container-add">
              <button className="menos" onClick={() => handleAdd("remove")}>
                <svg
                  id="circle-minus"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.591"
                  height="19.591"
                  viewBox="0 0 19.591 19.591"
                >
                  <path
                    id="Trazado_7"
                    data-name="Trazado 7"
                    d="M27.063,30.2H20.342a.558.558,0,0,0,.032,1.115H27.1a.545.545,0,0,0,.542-.573A.613.613,0,0,0,27.063,30.2Z"
                    transform="translate(-13.907 -20.994)"
                    fill="#043b93"
                  />
                  <path
                    id="Trazado_8"
                    data-name="Trazado 8"
                    d="M11.08,1.3a9.8,9.8,0,1,0,9.812,9.78A9.762,9.762,0,0,0,11.08,1.3Zm0,18.477a8.681,8.681,0,1,1,8.7-8.7A8.7,8.7,0,0,1,11.08,19.777Z"
                    transform="translate(-1.3 -1.3)"
                    fill="#043b93"
                  />
                </svg>
              </button>
              <input
                type="number"
                value={cantidad}
                onClick={() => setModalInputOpen(true)}
                readOnly
              />

              <button className="mas" onClick={() => handleAdd("add")}>
                <svg
                  id="circle-plus_1_"
                  data-name="circle-plus (1)"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.591"
                  height="19.591"
                  viewBox="0 0 19.591 19.591"
                >
                  <path
                    id="Trazado_9"
                    data-name="Trazado 9"
                    d="M27.063,23.045h-2.8v-2.8a.545.545,0,0,0-.573-.542h0a.545.545,0,0,0-.542.573v2.8h-2.8a.558.558,0,1,0,.032,1.115h2.8V27a.545.545,0,0,0,.573.542h0a.545.545,0,0,0,.542-.573v-2.8h2.8a.545.545,0,0,0,.542-.573A.613.613,0,0,0,27.063,23.045Z"
                    transform="translate(-13.907 -13.838)"
                    fill="#043b93"
                  />
                  <path
                    id="Trazado_10"
                    data-name="Trazado 10"
                    d="M11.08,1.3a9.8,9.8,0,1,0,9.812,9.78A9.762,9.762,0,0,0,11.08,1.3Zm0,18.477a8.681,8.681,0,1,1,8.7-8.7A8.7,8.7,0,0,1,11.08,19.777Z"
                    transform="translate(-1.3 -1.3)"
                    fill="#043b93"
                  />
                </svg>
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
