import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import "./search.css";
import { SearchContext } from "../../Context/SearchContext";
import { CategoryContext } from "../../Context/CategoryContext";

export default function Search() {
  const { search, setSearchHandle } = useContext(SearchContext);
  const { setCategoria, setSubCategoria } = useContext(CategoryContext);

  const location = useLocation();

  const handleChange = (event) => {
    setSearchHandle(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (location.pathname === "/") {
      setCategoria("");
      setSubCategoria("");
      window.location.href = "#/catalogo";
    }
  };

  return (
    <div className="container-search">
      <form onSubmit={handleSubmit}>
        <input
          type="search"
          placeholder="Busca por nombre, sku o marca"
          value={search}
          onChange={handleChange}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              handleSubmit(event);
            }
          }}
        />
        <button type="submit" className="button-search">
          <img
            src={`${process.env.PUBLIC_URL + "/assets/img/search-alt.svg"}`}
            alt="Buscar en Tecnoriego SRL"
          />
        </button>
      </form>
    </div>
  );
}
