import React, { useContext } from "react";
import "./categorias.css";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { getData, getCategorias } from "../../Helpers/getData";
import Loader from "../Generic/Loader";
import { CategoryContext } from "../../Context/CategoryContext";

export default function Categorias() {
  const { setCategoria, setSubCategoria } = useContext(CategoryContext);

  const { data, status } = useQuery(["sheet", "categorias"], async () => {
    const data = await getData();
    const categorias = await getCategorias();
    const dataEnd = data[1].map((item, index) => ({
      nombre: item,
      imagen: categorias.data.find((c) => c.nombre === item)?.imagen,
    }));
    const sortedDataEnd = dataEnd.sort((a, b) =>
      a.nombre.localeCompare(b.nombre)
    );
    return sortedDataEnd;
  });

  const handleSetCategoria = (cat) => {
    setCategoria(cat);
    setSubCategoria("");
  };

  if (status === "loading") {
    return <Loader />;
  }

  if (status === "error") {
    return (
      <p>
        Ocurrió un error al consultar el catálogo. Intente nuevamente más tarde
      </p>
    );
  }

  return (
    <div className="container-categorias">
      {data.map(({ nombre, imagen }, index) => {
        const string = nombre;
        const newString = string.replace(/\s+/g, "-").toLowerCase();        
        if (nombre !== "") {
          return (
            <div
              key={newString}
              className="categoria-card"
              style={{
                backgroundImage: `url(${!imagen.includes("http") ? `https://file.tecnoriegosrl.com.ar/${imagen}` : imagen})`,
              }}
            >
              <Link
                to={`/catalogo/`}
                onClick={() => handleSetCategoria(newString)}
              ></Link>
              <h2>{nombre}</h2>
            </div>
          );
        } else {
          return false;
        }
      })}
    </div>
  );
}
