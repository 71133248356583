import React, { createContext, useState } from "react";
export const CategoryContext = createContext();

export default function CategoryProvider({ children }) {
  const [categoria, setCategoria] = useState("");
  const [subCategoria, setSubCategoria] = useState("");

  return (
    <CategoryContext.Provider
      value={{
        categoria,
        setCategoria,
        subCategoria,
        setSubCategoria
      }}
    >
      {children}
    </CategoryContext.Provider>
  );
}